<template>
  <div class="row">
    <div id="content" class="col-sm-12 merchstoreconfig">
      <div class="page-login">
        <div class="account-border">
          <div class="row" style="width: 100%;">
            <div class="col-sm-12 customer-login">
              <h2>Store Management</h2>
              <div class="well" style="min-height: auto;">
                
                <div class="form-group">
                  <label class="control-label" for="input-name">Display Name / Store Name</label>
                  <br/>
                  <span style="margin-left: 1%; font-size: 14px; font-weight: 600;">{{ storeObj.store_name }}</span>
                </div>

                <div class="row">
                  
                  <div class="col-sm-6 form-group">
                    <label class="control-label" for="input-password">Email Address</label>
                    <br/>
                    <span style="margin-left: 1%; font-size: 14px; font-weight: 600;">{{ storeEmail }}</span>
                  </div>

                  <div class="col-sm-6 form-group">
                    <label class="control-label" for="input-password">Mobile No</label>
                    <br/>
                    <span style="margin-left: 1%; font-size: 14px; font-weight: 600;"> +94 {{ storeMobile }}</span>
                  </div>
                </div>
              </div>
                    <h3>Holiday Mode</h3>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="form-group required">
                          <toggle-switch
                            :options="myOptions"
                            :disabled="false"
                            @change="updateMap(switchVal)"
                            v-model="switchVal"
                            :value="switchVal"
                            :name="name"
                            group="switchGroup"
                          /> 

                          <p style="color: red">Enable this feature to hide your products from the Cochchi site temporarily. Once you disable this, all your products will go back to being displayed Cochchi.lk. Start and end times are effective from midnight 00:00</p>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group required">
                        
                         <label>Holiday Starting Date</label>
                          
                           <datepicker
                            placeholder="Select Date"
                            :bootstrap-styling="true"
                            :disabled="this.switchVal === 'Disable'"
                            ref="refDatePick"
                            v-model="holiday_start_date"
                            :disabledDates="disabledDates"
                            @selected="setdisablEndDates()"
                          >
                            <span slot="afterDateInput" class="animated-placeholder"></span>
                          </datepicker>

                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group required">
                        
                         <label>Holiday Reopening Date</label>
                          
                           <datepicker
                            placeholder="Select Date"
                            :bootstrap-styling="true"
                            ref="refDatePickEnd"
                            v-model="holiday_end_date"
                            :disabled="this.switchVal === 'Disable'"
                            :disabledDates="disabledFromDates"
                          >
                            <span slot="afterDateInput" class="animated-placeholder"></span>
                          </datepicker>

                        </div>
                      </div>
                    </div>
              
              <div
                class="bottom-form"
                style="margin-bottom: 28px; margin-top: -59px;"
              >
                <a
                  type="submit"
                  value="Back"
                  @click="backToStore"
                  class="btn merchprof-sbtn pull-left"
                  >Back</a
                >
                <input
                  type="submit"
                  value="Submit"
                  :disabled="dissableVal"
                  class="btn merchprof-sbtn pull-right"
                  @click="storeSubmit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinStore from "@/mixins/merchant/APIStore";
import mixinProfile from "@/mixins/merchant/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";
import Datepicker from "vuejs-datepicker";
import { async } from "q";
export default {
  mixins: [mixinCommon, mixinStore, mixinProfile],
  components: {
    VuePhoneNumberInput,
    Datepicker
  },
  data: function() {
    return {
      clickValue: true,
      isVisible: false,
      notification: "",
      storeObj: {},
      provinceArray: [],
      regionArray: [],
      storeEmail: "",
      storeMobile: "",
      holiday_start_date: null,
      holiday_end_date: null,
      disabledDates: {
        to: new Date(new Date().setDate(new Date().getDate() + 1) - 8640000)
      },
      disabledFromDates: {
        to: new Date(new Date().setDate(new Date().getDate() + 2) - 8640000)
      },
      changeBorder: {
        border: "1px solid #ccc",
      },
      dissableVal: false,
      hmobileValid: false,
      holidayMode: [
        {
          id: 1,
          name: "Disable"
        },
        {
          id: 2,
          name: "Enable"
        }
      ],
      breadcrumb: [
        {
          text: "Store Management",
          href: "/merchant-store",
          active: false,
        },
        {
          text: "Change Holiday Mode",
          href: "#",
          active: true,
        },
      ],
      switchVal: "Disable",
      myOptions: {
        layout: {
          color: 'black',
          backgroundColor: 'lightgray',
          selectedColor: 'white',
          selectedBackgroundColor: 'green',
          borderColor: '#ccc',
          fontFamily: 'Arial',
          fontWeight: 'normal',
          fontWeightSelected: 'bold',
          squareCorners: false,
          noBorder: false
        },
        size: {
          fontSize: 14,
          height: 4,
          padding: 0.7,
          width: 20
        },
        items: {
          delay: .4,
          preSelected: 'Disable',
          disabled: false,
          labels: [
            {name: 'Disable', color: 'white', backgroundColor: 'gray'}, 
            {name: 'Enable', color: 'white', backgroundColor: '#005baa'}
          ]
        }
      }
    };
  },
  computed: {
    sid() {
      let url = window.location.href.split("merchant-store-configure-holiday-mode/");
      return url[1];
    },
  },
  mounted() {
    this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);
  },
  watch: {
    holiday_start_date: function(val) {
      this.disabledDatesSet(val);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.getStoreByIdCheck();
    // this.holiday_start_date.setDate(this.holiday_start_date.getDate() + 1);
    // this.holiday_end_date.setDate(this.holiday_end_date.getDate() + 2);
  },
  methods: {
    backToStore() {
      this.$router.push("/merchant-store");
    },
    setdisablEndDates() {
      this.holiday_end_date = null;
      this.disabledDatesSet(this.holiday_start_date)
    },
    disabledDatesSet(val) {
      let toDate = val;
      if (this.sid !== "0") {
        this.disabledFromDates = {
            to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate() + 1) - 8640000)
        };
      } else {
        this.disabledFromDates = {
            to: new Date(new Date(toDate).setFullYear(new Date(toDate).getFullYear(),new Date(toDate).getMonth(),new Date(toDate).getDate() + 1) - 8640000)
        };
        
      }
    },
    setMobileNo: function(val) {
      if (val.length <= 9) {
        this.storeObj.phone = val;
      } else {
        this.storeObj.phone = val.substring(0, val.length - 1);
      }
    },
    validateEmail(val) {
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.test(String(val))) {
        this.changeBorder.border = "1px solid green";
        this.dissableVal = false;
      } else {
        this.changeBorder.border = "1px solid red";
        this.dissableVal = true;
      }
      if (val === "") {
        this.changeBorder.border = "1px solid #ccc";
        this.dissableVal = true;
      }
    },
    validatePhone1(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
        this.dissableVal = false;
      }
      if (key.length < 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
        this.dissableVal = true;
      }

      if (this.storeMobile === "") {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.storeMobile = res;
      key = res;

      if (key === "0") {
        this.storeMobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.storeMobile = key;

      if (key.length == 9) {
        if (key == this.storeMobile.substring(3)) {
          this.hmobileValid = false;
          this.hmobileError = "Existing Phone Number Detected";
          this.$refs.mobilecom1.$children[1].$el.firstChild.style.borderColor =
            "red";
          this.dissableVal = true;
        } else {
          this.hmobileValid = true;
          this.dissableVal = false;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        this.dissableVal = true;
        this.hmobileError = "Enter valid phone number";
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    storeSubmit: async function() {

      this.storeObj.email =  this.storeEmail;
      this.storeObj.phone = this.storeMobile;
      
      this.storeObj.holiday_start_date = moment(this.holiday_start_date).format("YYYY-MM-DD");
      this.storeObj.holiday_end_date = moment(this.holiday_end_date).format("YYYY-MM-DD");
      try {

        if (this.storeObj._id) {
          await this.putStore(this.storeObj);
        } else {
          await this.postStore(this.storeObj);
          this.postRequiredStage(6);
        }
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit success",
          showConfirmButton: false,
          timer: 1500,
        });

        let isUpdate = true
        this.$store.commit("setActiveStore", {
          action: isUpdate,
          payload: null
        });

        this.$router.push("/merchant-store");
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    postRequiredStage: async function(stage) {
      let obj = {
        complated_status: stage,
      };

      let response = await this.postDashboardStage(obj);

    },
    DeleteClick: async function(itemid) {
      this.addressArray.splice(1, 1);
      this.addressArray[0].is_biling_address = true;
      if (itemid) {
        await this.deleteStoreAddressById(itemid);
      }
    },
    getStoreByIdCheck: async function() {
      if (this.sid == "0") {
        let responseprofile = await this.getProfileBacicInfo();
        this.storeEmail = responseprofile.email
        this.storeMobile = responseprofile.mobile
        this.storeObj.active_status = responseprofile.active_status


        if (this.storeObj.active_status == 1) {
          this.switchVal = "Disable";
          this.holiday_start_date = null;
          this.holiday_end_date = null;
        }
        if (this.storeObj.active_status == 2) {
          this.switchVal = "Enable";
        }
        // this.storeObj.email = responseprofile.email;
        // this.storeObj.phone = responseprofile.mobile;
        // if (responseprofile.addresses.length > 0) {
        //   await this.changeCountries(responseprofile.addresses[0].country, 0);
        //   await this.changeProvince(responseprofile.addresses[0].province, 0);
        //   await this.changeRegion(responseprofile.addresses[0].region, 0);
        //   responseprofile.addresses[0].address =
        //     responseprofile.addresses[0].address_line_1;
        //   this.addressArray = [responseprofile.addresses[0]];
        // }
      }
      if (this.sid !== "0") {
        try {
          let response = await this.getStoreById(this.sid);
          this.storeObj = response;
          this.storeEmail = response.email;
          this.storeMobile = response.phone;
          this.holiday_start_date = null;
          this.holiday_end_date = null;

          if (this.storeObj.active_status == 1) {
            this.switchVal = "Disable";
            this.holiday_start_date = null;
            this.holiday_end_date = null;
          }
          if (this.storeObj.active_status == 2) {
            this.switchVal = "Enable";
          }
          this.holiday_start_date = response.holiday_start_date;
          this.holiday_end_date = response.holiday_end_date;

          if (response.holiday_start_date == null || response.holiday_start_date == undefined) {
            this.holiday_start_date = null;
            this.holiday_end_date = null;
          }

          if (this.holiday_end_date == null || this.storeObj.holiday_end_date == undefined) {
            this.holiday_start_date = null;
            this.holiday_end_date = null;
          }
          this.updateMap(this.switchVal);

        } catch (error) {
          console.log(error);
        }
      }
    },
    updateMap: function(val){
      if (val === "Enable") {
        this.storeObj.active_status = 2
        this.notification = "Enable this feature to hide your products from the Cochchi site temporarily. Once you disable this, all your products will go back to being displayed Cochchi.lk. Start and end times are effective from midnight 00:00";
      }
      if (val === "Disable") {
        this.storeObj.active_status = 1
        this.notification = "";
        this.holiday_start_date = null;
        this.holiday_end_date = null;
      }
    },
    
  },
};
</script>
<style scoped>
.merchstoreconfig .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.merchstoreconfig .merchprof-sbtn:hover {
  color: #000000;
}
</style>
